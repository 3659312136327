export const aemhContentTransformation = ({ fields }: ContentAem): Banner => {
  const {
    ctaUrl,
    imageAlt,
    assetImageLarge,
    assetImageMedium,
    assetImageSmall,
    alignment = 'center',
  } = fields;

  return {
    ctaUrl,
    imageAlt,
    imageSrcLarge: assetImageLarge.path,
    imageSrcMedium: assetImageMedium?.path || assetImageLarge.path,
    imageSrcSmall: assetImageSmall?.path || assetImageMedium?.path || assetImageLarge.path,
    alignment,
  };
};
