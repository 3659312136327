import React from 'react';
import { Picture } from './Picture';
import { MaybeLink } from './MaybeLink';
import { StyledBanner } from './style';

export const Banner = ({
  alignment,
  ctaUrl,
  imageAlt,
  imageSrcLarge,
  imageSrcMedium,
  imageSrcSmall,
}: Banner) => {
  return (
    <StyledBanner data-testid="Banner" alignment={alignment}>
      <MaybeLink ctaUrl={ctaUrl}>
        <Picture
          imageAlt={imageAlt}
          imageSrcLarge={imageSrcLarge}
          imageSrcMedium={imageSrcMedium}
          imageSrcSmall={imageSrcSmall}
        />
      </MaybeLink>
    </StyledBanner>
  );
};
export default Banner;
