/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useEffect, useState, createContext } from 'react';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

import { Banner } from './components/Banner';

import { aemhContentTransformation } from './aemhContentTransformation';

export const ContextServiceTracking = createContext<TrackingServiceV2 | undefined>(undefined);

export const FeatureApp: React.FC<FeatureAppProps> = ({
  serviceTracking,
  serviceContent,
}: FeatureAppProps) => {
  const [content, setContent] = useState(serviceContent?.getContent() as unknown as ContentAem);
  useEffect(() => {
    serviceContent?.onContentChange(() => {
      setContent(serviceContent?.getContent() as unknown as ContentAem);
    });
  }, [content]);

  return (
    <ContextServiceTracking.Provider value={serviceTracking}>
      {content?.fields ? <Banner {...aemhContentTransformation(content)} /> : null}
    </ContextServiceTracking.Provider>
  );
};

export default FeatureApp;
