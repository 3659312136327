import React from 'react';

import { StyledPicture, StyledImage } from './style';

export const Picture = ({
  imageAlt,
  imageSrcSmall,
  imageSrcMedium,
  imageSrcLarge,
}: PictureInput) => {
  return (
    <StyledPicture data-testid="Banner__picture">
      <source data-testid="Banner__sourceSmall" srcSet={imageSrcSmall} media="(max-width: 374px)" />
      <source
        data-testid="Banner__sourceMedium"
        srcSet={imageSrcMedium}
        media="((min-width: 375px) and (max-width: 767px))"
      />
      <source data-testid="Banner__sourceLarge" srcSet={imageSrcLarge} media="(min-width: 768px)" />

      <StyledImage data-testid="Banner__image" src={imageSrcSmall} alt={imageAlt} />
    </StyledPicture>
  );
};

export default Picture;
