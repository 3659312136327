import React from 'react';

export const MaybeLink: React.FC<MaybeLink> = ({ children, ctaUrl }: MaybeLink) => {
  return ctaUrl ? (
    <a data-testid="Banner__link" href={ctaUrl} target="_self">
      {children}
    </a>
  ) : (
    children
  );
};
