/**
 * This is the entry point for Feature Hub App integration
 */

import React, { StrictMode } from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';

import { FeatureApp } from './FeatureApp';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
      '@feature-hub/react': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const serviceTracking = featureServices['audi-tracking-service'];
    if (serviceTracking) {
      serviceTracking.featureAppName = 'fa-pencil-banner';
    }

    const serviceContent = featureServices['audi-content-service'];

    const serviceSerializedStateManager = featureServices['s2:serialized-state-manager'];
    const serviceAsyncSsrManager = featureServices['s2:async-ssr-manager'];

    if (serviceAsyncSsrManager) {
      // This 3 step process inspired by this link:
      // https://github.com/oneaudi/i18n-context?tab=readme-ov-file#featureapp-definition
      // Was getting TS errors by adding `async` within `scheduleRerender`
      const waitForThese = async () => {
        const content = (await serviceContent?.getContent()) as Record<string, unknown>;
        serviceSerializedStateManager?.register(() => JSON.stringify(content));
      };
      const promiseWaitForThese = waitForThese();
      serviceAsyncSsrManager.scheduleRerender(promiseWaitForThese);
    }

    return {
      render: () => {
        return (
          <StrictMode>
            <FeatureApp serviceTracking={serviceTracking} serviceContent={serviceContent} />
          </StrictMode>
        );
      },
    };
  },
};

export default featureAppDefinition;
