import styled from 'styled-components';

export const StyledBanner = styled.div<{ alignment?: string }>`
  display: flex;
  justify-content: ${(props) => (props.alignment ? props.alignment : 'center')};
  align-items: center;
  width: 100%;

  @media (max-width: 374px) {
    max-height: 50px;
    aspect-ratio: 320/50;
  }
  @media ((min-width: 375px) and (max-width: 767px)) {
    max-height: 90px;
    aspect-ratio: 728/90;
  }
  @media (min-width: 768px) {
    max-height: 90px;
    aspect-ratio: 970/90;
  }
`;

export const StyledPicture = styled.picture`
  display: flex;
`;

export const StyledImage = styled.img`
  display: inline-block;
  width: 100%;

  @media (max-width: 374px) {
    aspect-ratio: 320/50;
    max-width: 320px;
    max-height: 50px;
  }
  @media ((min-width: 375px) and (max-width: 767px)) {
    aspect-ratio: 728/90;
    max-width: 728px;
    max-height: 90px;
  }
  @media (min-width: 768px) {
    aspect-ratio: 970/90;
    max-width: 970px;
    max-height: 90px;
  }
`;
